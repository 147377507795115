import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Maintenance from "./pages/maintenance/maintenance";

function App() {
    return (
        <Router>
            {/* <Banner /> */}
            <Routes>
                <Route path="/" element={<Maintenance />} />
                {/* <Route path="/about" element={<About />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/gallery" element={<Gallery />} /> */}
                <Route path="*" element={<Maintenance />} />
                {/* <Route path="/news" element={<News />} /> */}
                {/* <Route path="/about" element={<About />} /> */}
                {/* <Route path="/contact" element={<Contact />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
