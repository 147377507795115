import React from "react";
import { Link } from "react-router-dom";
import "./maintenance.css";
export default function Maintenance() {
    return (
        <div className="maintenance-container">
            <h1>Under Maintenance</h1>
            <p>
                For current information, news and contact please{" "}
                <Link to="https://www.facebook.com/profile.php?id=100024911482696">
                    Visit our <span>Facebook</span> page
                </Link>
            </p>
        </div>
    );
}
